var generic = generic || {};
var site = site || {};

(function ($) {
  $(document).on('email_signup:success_but_signed_up', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-signed-up');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  $(document).on('email_signup:success_and_new', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-thank-you');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  $(document).on('email_signup:launch', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow();
    Drupal.behaviors.popupOfferV1.overwriteColorboxClose();
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  Drupal.behaviors.popupOfferV1 = {
  // Inline template name that is grabbed and shown in pop-up.
    templateName: 'popup_offer_v1',

    // Class name to be used for colorbox popup launch.
    colorBoxClassName: 'js-popup-offer-overlay-cbox popup-offer-cbox',

    // Cookie keeps count of pages viewed
    cookieName: 'popup_offer_values',
    // Cookie expires multiple times per day
    sessionCookieName: 'popup_offer_values_session',

    // The four larger elements that are displayed at different
    // stages of the popup process.
    popupElements: {
      first: '.js-popup-offer-outer-wrap',
      thankYou: '.js-popup-offer-thank-you',
      signedUp: '.js-popup-offer-signed-up',
      terms: '.js-popup-offer-terms',
      policy: '.js-popup-offer-policy'
    },

    // Track whether or not the .js-popup-offer-terms element is showing.
    termsShowing: false,
    policyShowing: false,

    // Store which div class was showing when the terms were opened.
    elementShowing: '',

    // Store the original colorbox close method.
    originalColorboxCloseMethod: $.colorbox.close,

    // Given the way the terms & conditions are shown in the colorbox overlay,
    // the close function must be overwritten a bit:
    // If the flag for termsShowing is true, just hide the terms, don't close the box.
    // Otherwise, act normally, by calling the original close method.
    overwriteColorboxClose: function () {
      var self = this;

      $.colorbox.close = function () {
        if (self.termsShowing) {
          self.hideTerms();
        } else if (self.policyShowing) {
          self.hidePolicy();
        } else {
          self.originalColorboxCloseMethod();
        }
      };
    },
    // Show the terms element and store what was being shown previously.
    showTerms: function (e) {
      var self = this;

      $(self.popupElements.terms).show();
      $(self.popupElements.policy).hide();
      $(self.elementShowing).hide();
      self.termsShowing = true;
    },

    // Hide the .js-popup-offer-terms element and show previous element.
    hideTerms: function () {
      var self = this;

      $(self.popupElements.terms).hide();
      $(self.popupElements.policy).hide();
      $(self.elementShowing).show();
      self.termsShowing = false;
    },

	  // Show the policy element and store what was being shown previously.
    showPolicy: function (e) {
      var self = this;

      $(self.popupElements.policy).show();
      $(self.popupElements.terms).hide();
      $(self.elementShowing).hide();
      self.policyShowing = true;
    },

    // Hide the .js-popup-offer-policy element and show previous element.
    hidePolicy: function () {
      var self = this;

      $(self.popupElements.policy).hide();
      $(self.popupElements.terms).hide();
      $(self.elementShowing).show();
      self.policyShowing = false;
    },

    // Make the RPC call for the email sign-up form in the pop-up.
    processForm: function () {
      var self = this;
      // Get all the fields in code because 'serialize' encodes bad emails and doesn't decode plus symbols.
      var formFields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'PC_EMAIL_ADDRESS',
        'BIRTH_MONTH',
        'BIRTH_DAY',
        'BIRTH_YEAR'
      ];
      // Create an object to hold all the parameters and their values.
      var paramObj = {};

      // Loop through each field and get its value.
      $.each(formFields, function (index, value) {
        var formField = $('.js-popup-offer-sign-up input[name=' + value + '], .js-popup-offer-sign-up select[name=' + value + ']');

        paramObj[value] = formField.val();
      });

      // microservice_api
      if (!Drupal.settings.use_jsonrpc) {
        generic.data.remove('UserId', 'session');
      }
      // Send the data via a JSON RPC call.
      generic.jsonrpc.fetch({
        apiFilterName: 'email-signup',
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getData();

          if (Drupal.settings.use_jsonrpc) {
          // Store returned boolean value for whether the user has already opted in the promotion before.
            var previouslyOptedInEmailPromotion = response.userinfo.previously_opted_in_email_promotion;
            // Store return boolean for still running the special offer codes.
            var specialOfferCodeStatus = response.userinfo.special_offer_code_status || 1;

            // If the user had not previously opted in AND the special offers are still running.
            if (!previouslyOptedInEmailPromotion && specialOfferCodeStatus) {
              $(self.popupElements.thankYou).show();
              self.elementShowing = self.popupElements.thankYou;
              // Else show that the user has already signed up for this offer.
            } else {
              $(self.popupElements.signedUp).show();
              self.elementShowing = self.popupElements.signedUp;
            }
          } else {
            if (!!response.uuid) {
              generic.data.set('UserId', response.uuid, { type: 'session' });

              // If the user has already signed up for this offer.  
              if ('initialOptIn' in response) {
                $(self.popupElements.signedUp).show();
                self.elementShowing = self.popupElements.signedUp;
              // Else show that the user has first time signed up for this offer.
              } else {
                $(self.popupElements.thankYou).show();
                self.elementShowing = self.popupElements.thankYou;
              }
            }
          }

          // Regardless, hide the original sign up form portion.
          $(self.popupElements.first).hide();
          $.colorbox.resize();
        },
        onFailure: function (jsonRpcResponse) {
        // Store messages and display them in error div element.
          var messages = jsonRpcResponse.getMessages();

          $('.js-popup-offer-error-messages').empty().removeClass('hidden');
          $('.js-popup-offer-sign-up').find('.error').removeClass('error');
          $.each(messages, function (index, value) {
            var errorInputfield = messages[index].tags[2].split('field.')[1];

            $('[name="' + errorInputfield.toUpperCase() + '"]', $('.js-popup-offer-sign-up')).addClass('error');
            $('.js-popup-offer-error-messages').append('<span>' + messages[index].text + '</span>');
          });
          $.colorbox.resize();
        }
      });
    },

    // Launch the popup window with the appropriate element visible.
    launchPopupWindow: function (elementToShow) {
      var self = this;
      var okayToLaunchPopup = true;
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });
      // Get value of data attribute 'enable-popup', it's should be either 'yes' or 'no'.
      var $templateContext = $(templateHTML);
      var enablePopup = $templateContext.data('enable-popup');
      var popupOverlayWidth = $templateContext.data('overlay-width');
      var popupWidth = '511';

      if (popupOverlayWidth) {
        popupWidth = popupOverlayWidth;
      }

      if (typeof enablePopup === 'undefined') {
        enablePopup = 'yes';
      } // If not set, give it the value of 'yes'.

      // Set a default elementToShow just in case.
      if (elementToShow === undefined) {
        elementToShow = self.popupElements.first;
        // If there was no elementToShow parameter, then this was triggered by visiting some page, not triggered by an email signup.
        // Therefore, the value of enablePopup must be used to see if the popup should actually launch.
        if (enablePopup === 'no' || enablePopup === 'false' || !enablePopup) {
          okayToLaunchPopup = false;
        }
      }

      if (okayToLaunchPopup) {
      // When mobile, override any height/width and set to 100%
        if ($(window).width() <= 768) {
          $.colorbox({
            html: templateHTML,
            width: '320px',
            height: '325px',
            display: 'inline',
            className: self.colorBoxClassName,
            trapFocus: false
          });
          $('#cboxOverlay').hide();
        } else {
        // Launch the popup with colorbox.
          generic.overlay.launch({
            content: templateHTML,
            width: popupWidth,
            height: 352,
            cssClass: self.colorBoxClassName,
            trapFocus: false
          });
        }

        // Loop through all popupElements and only show the one specified
        // in the parameter elementToShow (or the default value).
        for (var elemKey in self.popupElements) {
          if (self.popupElements.hasOwnProperty(elemKey)) {
            var elemValue = self.popupElements[elemKey];

            if (elemValue === elementToShow) {
              $(elemValue).show();
              self.elementShowing = elemValue;
            } else {
              $(elemValue).hide();
            }
          }
        }
      }
    },

    smsSignupEvent: function () {
      var $emailSignUpForm = $('#email-sms-signup');
      var $errorElem = $('#site-email-sms-signup-error');

      $emailSignUpForm.on('submit', function (submitEvt) {
        var paramObj = {};

        submitEvt.preventDefault();
        $('input', $emailSignUpForm).removeClass('error');

        $.each($emailSignUpForm.serializeArray(), function () {
          var param = arguments[1];

          paramObj[param.name] = param.value;
        });
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function () {
            $errorElem.addClass('hidden');
            window.location.replace('account/email-sms-thank-you.tmpl');
          },
          onFailure: function (jsonRpcResponse) {
            var errorObjectsArray = jsonRpcResponse.getMessages();
            var errorList = errorObjectsArray.length;
            var errorHTML = '';
            var elemOne;
            var elemTwo;
            var fieldTags;

            for (elemOne = 0; elemOne < errorList; elemOne++) {
              errorHTML += '<li>' + errorObjectsArray[elemOne].text + '</li>';
              for (elemTwo = 2; elemTwo < errorObjectsArray[elemOne].tags.length; elemTwo++) {
                fieldTags = errorObjectsArray[elemOne].tags[elemTwo].substr(6).toUpperCase();

                $("[name='" + fieldTags + "']").addClass('error');
              }
            }
            $errorElem.html(errorHTML).show();
            $errorElem.removeClass('hidden');

            return false;
          }
        });
      });
    },

    bindEvents: function () {
      var self = this;

      $('.js-popup-offer-footer a').click(function (e) {
        e.preventDefault();
        self.showTerms();
      });
      $('.js-popup-offer-footer a.terms_link').click(function (e) {
        e.preventDefault();
        self.showTerms();
      });
      $('.js-popup-offer-footer a.policy_link').click(function (e) {
        e.preventDefault();
        self.showPolicy();
      });
      $('.js-popup-offer-terms-back').click(function (e) {
        e.preventDefault();
        self.hideTerms();
      });
      $('.js-popup-offer-policy-back').click(function (e) {
        e.preventDefault();
        self.hidePolicy();
      });
      $('.js-popup-offer-submit').click(function (e) {
        e.preventDefault();
        self.processForm();
      });
    },

    attach: function (context, settings) {
      var self = this;
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });
      // Get value of data attributes
      var $templateContext = $(templateHTML);
      var expiresPerDay = parseFloat(1 / $templateContext.data('times-per-day')); // Default 0.5 == twice per day
      var pageCount = parseInt($templateContext.data('page-count')); // Default 2
      // Count pages viewed per session
      var count = 0;
      // Default to 'yes'
      var cookieVal = 'yes';
      // Check for a value for the day long cookie & the session cookie
      var cookieValue = $.cookie(self.cookieName); // Can contain [count, 'yes']
      var hidePopupSessionCookie = $.cookie(self.sessionCookieName); // expires # times per day.
      var $smsPopup = $templateContext.find('#email-sms-signup');
      var smsPopupLength = $smsPopup.length;

      if (cookieValue != null) {
        try {
          var hidePopupCookie = JSON.parse(cookieValue);
        } catch (err) {
          $.cookie(self.cookieName, null);
        }
      } else {
        var hidePopupCookie = null;
      }

      // If a returning visitor within same session
      if (hidePopupCookie != null && _.isArray(hidePopupCookie) && hidePopupSessionCookie != null) {
      // get values
        count = parseInt(hidePopupCookie[0]);
        cookieVal = hidePopupCookie[1];
        // increment page counter
        count++;
        // console.log({'count':count,'hidePopupCookie':hidePopupCookie,'hidePopupSessionCookie':hidePopupSessionCookie}); // DEBUG
        // update cookie
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
      // console.log('returning visitor');
      } else {
        // New visitor or new session
        // Set new cookies
        count = 1;
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
        var date = new Date();
        var minutes = expiresPerDay * 24 * 60;
        var whenExpires = date.setTime(date.getTime() + (minutes * 60 * 1000));

        // MX override of cookie for always showing offers popup
        if (Drupal.settings.mx_newsletter_cookie_functionality) {
          sessionStorage.setItem('mx_newsletter_session', 'mx_newsletter_session_true');
        }
        $.cookie(self.sessionCookieName, 'hidden', {
          expires: whenExpires,
          path: '/'
        });
        // console.log('new session visitor');
      }

      // Check page counter
      if (count == pageCount && cookieVal != 'no' || (Drupal.settings.mx_newsletter_cookie_functionality && !sessionStorage.getItem('mx_newsletter_session'))) {
        // launch popup
        self.launchPopupWindow();
        self.overwriteColorboxClose();
        self.bindEvents();
        if (smsPopupLength > 0) {
          self.smsSignupEvent();
        }
        // deny further popups until session is reset
        cookieVal = 'no';
        // reset cookies
        count = 1;
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
        if (Drupal.settings.mx_newsletter_cookie_functionality) {
          sessionStorage.setItem('mx_newsletter_session', 'mx_newsletter_session_true');
        }
        var date = new Date();
        var minutes = expiresPerDay * 24 * 60;
        var whenExpires = date.setTime(date.getTime() + (minutes * 60 * 1000));

        $.cookie(self.sessionCookieName, 'hidden', {
          expires: whenExpires,
          path: '/'
        });
        console.log('launch popup');
        $.colorbox.resize();
      }
    }
  };
})(jQuery);
